import { DELETE_ACTIVITY_FAILURE, DELETE_ACTIVITY_SUCCESS } from "../actions/Types";

const initialState = {
    activities: [],
    error:null
};

const ActivitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_ACTIVITIES':
            return {
                ...state,
                activities: action.payload
            };
        case 'ADD_ACTIVITY':
            return {
                ...state,
                activities: [...state.activities, action.payload]
            };
        case 'EDIT_ACTIVITY':
            return {
                ...state,
                activities: state.activities.map(activity =>
                    activity.id === action.payload.id ? action.payload.updatedActivity : activity
                )
            };

            case DELETE_ACTIVITY_SUCCESS:
                return {
                    ...state,
                    activities: state.activities.filter(activity => activity.id !== action.payload),
                };
            case DELETE_ACTIVITY_FAILURE:
                return {
                    ...state,
                    error: action.payload,
                };
        default:
            return state;
    }
};

export default ActivitiesReducer;
