import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaTasks, FaEnvelope, FaNewspaper, FaBars, FaSignOutAlt, FaTimes } from 'react-icons/fa';
import './SideBar.css';
import { logoutUser } from '../../actions/LaunchActions';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';

const SideBar = ({ children }) => {
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const toggleSidebar = () => setIsOpen(!isOpen);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const menuItem = [
        {
            path: "/",
            name: "Home",
            icon: <FaHome />
        },
        {
            path: "/activities",
            name: "Activities",
            icon: <FaTasks />
        },
        {
            path: "/messages",
            name: "Messages",
            icon: <FaEnvelope />
        },
        {
            path: "/newsletters",
            name: "Newsletters",
            icon: <FaNewspaper />
        }
    ];

    // Function to close sidebar on menu item click for mobile
    const handleMenuItemClick = () => {
        // if (window.innerWidth <= 768) {
        setIsOpen(false);
        // }
    };
    const confirmLogout = () => {
        dispatch(logoutUser());
    };

    return (
        <div className="sidebar-container">
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="toggle-btn" onClick={toggleSidebar}>
                    {
                        isOpen ?
                            <FaTimes />
                            :
                            <FaBars />
                    }
                </div>
                <div className="menu">
                    {menuItem.map((item, index) => (
                        <NavLink to={item.path} key={index} className="link" activeClassName="active" onClick={handleMenuItemClick}>
                            <div className="icon">{item.icon}</div>
                            {isOpen && <div className="link_text">{item.name}</div>}
                        </NavLink>
                    ))}
                    <div className="link_logout" onClick={() => setIsModalVisible(true)}>
                        <div className="icon_logout">
                            <FaSignOutAlt />
                        </div>
                        {isOpen && <div className="link_text_logout">Logout</div>}
                    </div>
                </div>
            </div>
            <main className={`content ${!isOpen ? 'shifted' : ''}`}>
                {children}
            </main>
            <Modal
                title="Confirm Logout"
                visible={isModalVisible}
                onOk={confirmLogout}
                onCancel={() => setIsModalVisible(false)}
                okText="Yes"
                cancelText="No"
            >
                <p>Are you sure you want to logout?</p>
            </Modal>
        </div>
    );
};

export default SideBar;
