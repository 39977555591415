import {combineReducers} from "redux";

//import reducers
import MessagesReducer from "./MessagesReducer";
import ActivitiesReducer from "./ActivitiesReducer";
import NewsletterReducer from "./NewslettersReducer";
// import AnalyticsReducer from "./AnalyticsReducer";
// import contactReducer from "./ContactReducer";

export default combineReducers({
    activities: ActivitiesReducer,
    messages: MessagesReducer,
    newsletters: NewsletterReducer,
    
});