import React, { useState, useEffect, Suspense, lazy } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';


import logo from './assets/logo.png';

//file imports
// import Activities from './components/admin/Activities';
// import Contacts from './components/superAdmin/Contacts';
// import Activity from './components/admin/Activity';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// import Team from './components/team/Team';
// import Employee from './components/team/Employee';
// import Footer from './components/footer/Footer';
// import NotFound from './components/notFound/NotFound';
// import Offers from './components/offers/Offers';
import SideBar from './components/sideBars/SideBar';
import Footer from './components/footer/Footer';
// import Home from './components/home/Home';
// import Activities from './components/activities/Activities';
// import Messages from './components/messages/Messages';
// import Newsletters from './components/newsletters/Newsletters';
// import Login from './components/launch/Login';


const Home = lazy(() => import('./components/home/Home'));
const Activities = lazy(() => import('./components/activities/Activities'));
const Messages = lazy(() => import('./components/messages/Messages'));
const Newsletters = lazy(() => import('./components/newsletters/Newsletters'));
const Login = lazy(() => import('./components/launch/Login'));


const firebaseConfig = {
  apiKey: "AIzaSyDoVRVwEd42LrysISbqjM73SQVTdGIiOW8",
  authDomain: "ngnantranga-web.firebaseapp.com",
  projectId: "ngnantranga-web",
  storageBucket: "ngnantranga-web.appspot.com",
  messagingSenderId: "351772340608",
  appId: "1:351772340608:web:75953f8a5e0ebf15585165",
  measurementId: "G-YS4LQ42S5B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

const App = () => {

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);


  const renderLogin = () => {
    return (
      // <SideBar>
        <div className="mainContainer">
          <Suspense fallback={
            <div className='center-placeholder'>
              <img src={logo} alt='logo' />
            </div>
          }>
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
          {/* <Footer /> */}
          </Suspense>
        </div>
      // </SideBar>
    );
  };

  const renderAccess = () => {
    // if (user) {
    //   if (!_.isEmpty(myProfile) && myProfile.role === "Super Admin") {
    //     return (
    //       <SuperAdminSideBars>
    //         <div className="mainContainer">
    //           <Routes>
    //             {/* <Route path='/' element={<Home />} />
    //             <Route path='/contacts' element={<Contacts />} />
    //             <Route path='/bookings' element={<Booking />} /> */}
    //           </Routes>
    //         </div>
    //       </SuperAdminSideBars>
    //     );
    //   } else {
    //     return (
    //       <AdminTopbars>
    //         <div className="mainContainer">
    //           <Routes>
    //             <Route path='/' element={<Home />} />
    //             <Route path='/activities' element={<Activities />} />
    //             <Route path='/messages' element={<Messages />} />
    //             <Route path='/newsletter' element={<Newsletter />} />
    //             <Route path="/activity/:id" element={<Activity />} />
    //           </Routes>
    //         </div>
    //       </AdminTopbars>
    //     );
    //   }
    // } else {

    // if (!user) {
    //   <Router>
    //     <Switch>
    //       <Route exact path="/login">
    //         {user ? <Redirect to="/" /> : <Login />}
    //       </Route>
    //       <Route path="*">
    //         <NotFound />
    //       </Route>
    //     </Switch>
    //   </Router>
    // } else {

    return (
      <SideBar>
        <div className="mainContainer">
        <Suspense fallback={
            <div className='center-placeholder'>
              <img src={logo} alt='logo' />
            </div>
          }>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/activities' element={<Activities />} />
            <Route path='/messages' element={<Messages />} />
            <Route path='/newsletters' element={<Newsletters />} />
          </Routes>
          <Footer />
          </Suspense>
        </div>
      </SideBar>
    );
  };


  return (
    <BrowserRouter>
      {user ?
        renderAccess()
        :
        renderLogin()
      }
    </BrowserRouter>
  );


};

export default App;
