// LaunchActions.js
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../App';
import { LOGOUT } from './Types';

export const loginUser = (email, password) => async (dispatch) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        // Optionally dispatch any actions or handle post-login logic here
    } catch (error) {
        console.error("Login error:", error);
        // Optionally dispatch an error action or show a notification
    }
};


// Action creator for logging out
export const logoutUser = () => async (dispatch) => {

    try {
        await signOut(auth);
        dispatch({ type: LOGOUT });
        // Optionally handle additional logic after logout
    } catch (error) {
        console.error("Logout error:", error);
        // Optionally dispatch an error action or show a notification
    }
};

