import React from 'react';
import ReactDOM from 'react-dom/client';

// Redux imports
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk'; // Named import for thunk

// Reducer import
import Reducer from './reducers';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Create store with middleware
const store = createStore(
    Reducer,
    applyMiddleware(thunk)
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
