import {
    FETCH_ALL_NEWSLETTERS_REQUEST,
    FETCH_ALL_NEWSLETTERS_SUCCESS,
    FETCH_ALL_NEWSLETTERS_FAILURE,
    DELETE_NEWSLETTER_REQUEST,
    DELETE_NEWSLETTER_SUCCESS,
    DELETE_NEWSLETTER_FAILURE
} from '../actions/Types';

const initialState = {
    newsletters: [],
    loading: false,
    error: null,
};

const NewsletterReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_NEWSLETTERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_ALL_NEWSLETTERS_SUCCESS:
            return {
                ...state,
                newsletters: action.payload,
                loading: false,
                error: null,
            };
        case FETCH_ALL_NEWSLETTERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case DELETE_NEWSLETTER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case DELETE_NEWSLETTER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_NEWSLETTER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default NewsletterReducer;
