import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-links">
                <NavLink to="/" className="footer-link" >Home</NavLink>
                <NavLink to="/activities" className="footer-link" >Activities</NavLink>
                <NavLink to="/messages" className="footer-link" >Messages</NavLink>
                <NavLink to="/newsletters" className="footer-link" >Newsletters</NavLink>
            </div>
            <div className="footer-powered">
                Powered by <a href="https://tanzator.com" target="_blank" rel="noopener noreferrer">Tanzator</a>
            </div>
        </footer>
    );
};

export default Footer;
