const initialState = {
    messages: [],
};

const MessagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_MESSAGES':
            return {
                ...state,
                messages: action.payload,
            };
        default:
            return state;
    }
};

export default MessagesReducer;
